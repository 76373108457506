import { render, staticRenderFns } from "./OneCategoryItem.vue?vue&type=template&id=6f358e8c&scoped=true&functional=true&"
var script = {}
import style0 from "./OneCategoryItem.vue?vue&type=style&index=0&id=6f358e8c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.9.8_css-loader@4.3.0_vue-template-compiler@2.7.16_webpack@4.47.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "6f358e8c",
  null
  
)

export default component.exports